import React, { useState } from "react";
import Button from "../../../../helpers/button";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { showCurrencyPrice, amountFormatter } from "../../../../helpers/constants/functions"
import "./styles.scss";

function Filters(props) {

  const { setRange, t, currency } = props;

  const maxFilterPrice = showCurrencyPrice(1000, currency)

  const [setMin, setMinValue] = useState(0);
  const [setMax, setMaxValue] = useState(maxFilterPrice);



  const setMinPrice = (minValue) => {
    setMinValue(minValue);
  };
  const setMaxPrice = (maxValue) => {
    setMaxValue(maxValue);

  };

  return (
    <div className="sidebar">
      
        <div className="sidebar-filter">
          <div className="sidebar-widget wow fadeInUp">
            <div className="widget-header">
              <h4 className="widget-title">{t('Table_cart_product_price')}</h4>
            </div>
            <div className="sidebar-widget-body m-t-10">
              <div className="price-range-holder">
                <label style={{ display: "flex", justifyContent: "space-between" }}>
                  <span className="pull-left">{currency}{amountFormatter(setMin)}</span>
                  <span className="pull-right">{currency}{amountFormatter(setMax)}</span>
                </label>
                <section class="range-slider">
                  <span class="rangeValues"></span>
                  <input type="range"
                    id="volume"
                    name="volume"
                    min="0"
                    max={maxFilterPrice}
                    value={setMin}
                    onChange={(e) => setMinPrice(e.target.value)} step="1" />

                  <input type="range"
                    id="cowbell"
                    name="cowbell"
                    min="0"
                    max={maxFilterPrice}
                    value={setMax}
                    onChange={(e) => setMaxPrice(e.target.value)} step="1" />
                </section>

                {/* 
                <span className="min-max">
                 
                  <input
                    type="range"
                    id="volume"
                    name="volume"
                    min="0"
                    max={maxFilterPrice}
                    value={setMin}
                    onChange={(e) => setMinPrice(e.target.value)}
                  />
                </span>

                <div>
                  <span className="min-max">
                    <label htmlFor="cowbell">

                    </label>
                    <input
                      type="range"
                      id="cowbell"
                      name="cowbell"
                      min="0"
                      max={maxFilterPrice}
                      value={setMax}
                      onChange={(e) => setMaxPrice(e.target.value)}
                    />
                  </span>
                </div> */}
              </div>

              <div></div>
              <Button
                className="lnk btn btn-primary"
                text={t('Show')}
                onClick={() => setRange(setMin, setMax)}
              />
            </div>
          </div>
        </div>
      
    </div>
  );
}


const mapStateToProps = (state) => ({
  currency: state.MenuDataReducer.currency,
});

export default withRouter(compose(connect(mapStateToProps, {}), withNamespaces())(Filters));


//style="border:0; color:#666666; font-weight:bold;text-align:center;"
