import React, { useEffect, useState } from "react";

import { GET_PRODUCTS_LIST_BY_CAT_ID, ITEMS_ON_THE_PAGE } from "../../../../helpers/constants"
import axiosFetch from "../../../../helpers/axios"
import CustomCarousel from "../../../../helpers/custom-carousel"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import OneProduct from '../../../../helpers/one-product'

import "./styles.scss"
const CategoryCarousel = ({ id, finalName }) => {

  const [widgetProducts, setWidgetProducts] = useState([])

  useEffect(() => {

    const formData = new FormData()
    formData.append("сategoryId", id)
    formData.append("currentPage", 0)
    formData.append("itemsOnPage", ITEMS_ON_THE_PAGE)
    formData.append("minValue", 0)
    formData.append("maxValue", 1000)

    const productsResult = axiosFetch(GET_PRODUCTS_LIST_BY_CAT_ID, formData)
    //return productsResult
    Promise.resolve(productsResult).then((pInfo) => {

      setWidgetProducts(pInfo)
    })

  }, [id])


  const renderCategoryCarousel = () => {
    const productsArr = []
    widgetProducts.forEach((oneProduct, index) => {
      productsArr.push({
        oneProduct: <OneProduct oneProduct={oneProduct} className={"item"} showAddToCart={true} key={index} />,
      })
    })
    const productsList = productsArr?.length === 0
    if (!productsList) {

      return (
        <div className={`sidebar-carousel ${productsList && "hide-category-slider"}`} key={2}>
          <h2>{finalName}</h2>
          {/* {productsList && productsList.length > 0 && renderProductsItems(productsList)} */}
          <CustomCarousel swipeable={true}
            draggable={true}
            showDots={false}
            arrows={true}
            infinite={true}
            autoPlay={true}
            desktopItems={4}
            autoPlaySpeedInSec={2 + 2.5}
            carouselItems={productsArr}
          />
        </div>
      )
    }
  }
  return (
    <div className="category-carousel">
      {renderCategoryCarousel()}
    </div>
  );

}


const mapStateToProps = (state) => ({
  menuData: state.MenuDataReducer,
  lng: state.PrefsReducer.lng,
  globalSearchResult: state.MenuDataReducer.globalSearchResult,
});

export default withRouter(
  compose(
    connect(mapStateToProps, {}),
    withNamespaces()
  )(CategoryCarousel)
);