import React, { useState } from 'react';
import { SET_ORDER, SET_ORDER_ITEMS, DELETE_CART_ITEMS, SEND_NOTIFICATION_TO_ADMIN } from "../../../../../helpers/constants"
import { isValidEmail } from "../../../../../helpers/constants/functions"
import axiosFetch from "../../../../../helpers/axios"
import CookieHelper from "../../../../../helpers/cookie-helper";
import { getCartData, getCartInfoFirstLoad } from "../../../../../actions/AddToCartActionCreator"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from "../../../../../helpers/spinner";

import './styles.scss'

function DeliveryForm(props) {
    const { cartItems, totalSumm, deliveryPriceFinal, totalSummFinal, deliveryType, redirect, t } = props
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [note, setNote] = useState("")
    const [codePostal, setCodePostal] = useState("")
    const [city, setCity] = useState("")
    const [judet, setJudet] = useState("")
    const [facturaType, setFacturaType] = useState('pf')


    const [companyName, setCompanyName] = useState("")
    const [codeFiscal, setCodeFiscal] = useState("")
    const [tradeRegistry, setTradeRegistry] = useState("")
    const [companyPhone, setCompanyPhone] = useState("")
    const [companyFax, setCompanyFax] = useState("")
    const [companyAdress, setCompanyAdress] = useState("")
    const [companyCity, setCompanyCity] = useState("")
    const [companyJudet, setCompanyJudet] = useState("")
    const [paymentMethod, setPaymentMethod] = useState("ramburs-la-livrare")
    const [deliveryMethod, setDeliveryMethod] = useState("curier-rapid")
    const [adress, setAdress] = useState("")


    // const [count, setCount] = useState(1)
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabledButton] = useState(false)

    // const changeCount = (action = "inc") => {
    //     if (action === "inc") {
    //         setCount(count + 1)
    //     } else if (count > 1) {
    //         setCount(count - 1)
    //     }
    // }

    const setErrorAndRemoveLoading = errorValue => {
        setError(errorValue)
        setIsLoading(false)
    }

    const setOrder = () => {
        setIsLoading(true)

        const emailRegex = isValidEmail(email)
        if (email.length > 0 && !emailRegex) {
            setErrorAndRemoveLoading(t('Email_error'))
            setIsLoading(false)
            return false
        }
        setIsDisabledButton(true)

        const userId = 0

        const token = CookieHelper("get", "token");
        const formData = new FormData();
        formData.append("userId", userId)
        formData.append("name", name)
        formData.append("phone", phone)
        formData.append("email", email)
        formData.append("note", note)
        formData.append("adress", adress)
        formData.append("token", token)
        formData.append("deliveryType", deliveryType)
        formData.append("totalSumm", totalSumm)
        formData.append("deliveryPriceFinal", deliveryPriceFinal)
        formData.append("totalSummFinal", totalSummFinal)

        formData.append("companyName", companyName)
        formData.append("codeFiscal", codeFiscal)
        formData.append("tradeRegistry", tradeRegistry)
        formData.append("companyPhone", companyPhone)
        formData.append("companyFax", companyFax)
        formData.append("companyAdress", companyAdress)
        formData.append("companyCity", companyCity)
        formData.append("companyJudet", companyJudet)
        formData.append("facturaType", facturaType)
        formData.append("paymentMethod", paymentMethod)
        formData.append("deliveryMethod", deliveryMethod)

        formData.append("codePostal", codePostal)
        formData.append("city", city)
        formData.append("judet", judet)





        let totalCartItems = 0


        axiosFetch(SET_ORDER, formData).then(orderId => {
            cartItems.forEach(cartLine => {
                totalCartItems++
                const { id, price, count } = cartLine;

                const fd = new FormData();

                fd.append("orderId", orderId)
                fd.append("productId", id)
                fd.append("productPrice", price)
                fd.append("productCount", count)

                axiosFetch(SET_ORDER_ITEMS, fd).then(() => {
                    if (totalCartItems === cartItems.length) {

                        const delFormData = new FormData();

                        delFormData.append("token", token)

                        axiosFetch(DELETE_CART_ITEMS, delFormData).then(() => {
                            setIsLoading(false)
                            redirect()
                            setIsDisabledButton(false)
                        })

                        const sendData = new FormData();

                        sendData.append("orderId", orderId)
                        axiosFetch(SEND_NOTIFICATION_TO_ADMIN, sendData)

                    }
                })
            })
        })

    }

    const setNameCheck = (value) => {
        // const regex = /^[a-zA-Zа-яА-Я]{0,20}$/
        // if (regex.test(value)) {
        setName(value)
        // }
    }

    const setPhoneCheck = (value) => {
        const regex = /^[0-9]+$/
        if ((regex.test(value) && value.length <= 11) || value === "") {
            setPhone(value)
        }
    }

    const renderFirmaBlock = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-4 ">
                        <div className="form-group">
                            <label className="info-title">
                                {t('Your_name')}*:<span>*</span>
                            </label>
                            <input type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Your_name')}
                                value={companyName}
                                onChange={(e) => { setError(); setCompanyName(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label
                                className="info-title" >
                                {t('Code_fiscal')}<span>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Code_fiscal')}
                                value={codeFiscal}
                                onChange={(e) => { setError(); setCodeFiscal(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="info-title">
                                {t('Firma_refistration')}
                            </label>
                            <input
                                type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Firma_refistration')}
                                value={tradeRegistry}
                                onChange={(e) => { setError(); setTradeRegistry(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 ">
                        <div className="form-group">
                            <label className="info-title">
                                {t('Your_phone')}<span>*</span>
                            </label>
                            <input type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Your_phone')}
                                value={companyPhone}
                                onChange={(e) => { setError(); setCompanyPhone(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <div className="form-group">
                            <label className="info-title">
                                {t('Your_fax')}<span>*</span>
                            </label>
                            <input type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Your_fax')}
                                value={companyFax}
                                onChange={(e) => {
                                    setError();
                                    setCompanyFax(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 ">
                        <textarea
                            className="form-control unicase-form-control"
                            placeholder={t('Your_adress')}
                            onChange={(e) => { setError(); setCompanyAdress(e.target.value) }}
                            value={companyAdress} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 ">
                        <div className="form-group">
                            <label className="info-title">
                                {t('Your_district')}<span>*</span>
                            </label>
                            <input type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Your_district')}
                                value={companyJudet}
                                onChange={(e) => { setError(); setCompanyJudet(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label
                                className="info-title" >
                                {t('Your_city')}<span>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Your_city')}
                                value={companyCity}
                                onChange={(e) => { setError(); setCompanyCity(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderPFBlock = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <label className="info-title">
                            {t('Payment_information')}*:
                        </label>
                        <select
                            className="form-control unicase-form-control selectpicker"
                            onChange={(e) => { setError(); setPaymentMethod(e.target.value) }}
                        >
                            <option value="ramburs-la-livrare">{t('Samovivoz')}</option>
                            <option value="transfer-bancar">{t('Card_payment')}</option>
                            <option value="card-online">Card online</option>
                            {/* <option value="6">Rate prin TBI Bank</option>
                            <option value="5">Rate prin Netopia Payments</option> */}

                        </select>
                    </div>

                    <div className="col-md-6">
                        <label className="info-title">
                            {t('Delivery_method')}*:
                        </label>
                        <select
                            onChange={(e) => { setError(); setDeliveryMethod(e.target.value) }}
                            className="form-control unicase-form-control selectpicker"
                        >
                            <option value="curier-rapid">{t('Delivery_courier')}</option>
                            <option value="ridicare-sediu-comfortmat">{t('Myself_go_in_office')}</option>
                        </select>
                    </div>

                </div>

                <div className="form-group">
                    <label className="info-title">
                        {t('Your_notes')}<span>*</span>
                    </label>
                    <textarea
                        className="form-control unicase-form-control"
                        onChange={(e) => { setError(); setNote(e.target.value) }}
                        value={note} />
                </div>
            </>
        )
    }

    const renderContent = () => {
        return (
            <div className="contact-form">
                <div className="col-md-12 contact-title">
                    <h4>{t('Order_form')}</h4>
                </div>
                <div className="row">
                    <div className="col-md-4 ">
                        <div className="form-group">
                            <label className="info-title">
                                {t('Your_name')}<span>*</span>
                            </label>
                            <input type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Your_name')}
                                value={name}
                                onChange={(e) => { setNameCheck(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label
                                className="info-title" >
                                {t('Phone')}<span>*</span>
                            </label>
                            <input
                                type="number"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Phone')}
                                value={phone}
                                onChange={(e) => { setError(); setPhoneCheck(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="info-title">
                                Email
                            </label>
                            <input
                                type="email"
                                className="form-control unicase-form-control text-input"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => { setError(); setEmail(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 ">
                        <div className="form-group">
                            <label className="info-title">
                                {t('Your_district')}<span>*</span>
                            </label>
                            <input type="email"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Your_district')}
                                value={judet}
                                onChange={(e) => { setError(); setJudet(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label
                                className="info-title" >
                                {t('Your_city')}<span>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Your_city')}
                                value={city}
                                onChange={(e) => { setError(); setCity(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="info-title">
                               {t('Your_code_postal')}
                            </label>
                            <input
                                type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Your_code_postal')}
                                value={codePostal}
                                onChange={(e) => { setError(); setCodePostal(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>



                {/* <div className="col-md-4">

                    <p className="form-row form-row-first mx-number woocommerce-validated">
                        <span className="minus" onClick={() => { setError(); changeCount("dec") }}>-</span>
                        <label className="">{t('Cutlery')}</label>
                        <span className="woocommerce-input-wrapper">
                            <input type="number" className="input-text " name="shipping_cutlery" placeholder="" value={count} min="0" max="10" />
                        </span>
                        <span className="plus" onClick={() => { setError(); changeCount() }}>+</span>
                    </p>

                </div> */}




                <div className="form-group">
                    <label className="info-title">
                        {t('Adres')}<span>*</span>
                    </label>
                    <textarea className="form-control unicase-form-control"
                        onChange={(e) => { setError(); setAdress(e.target.value) }} value={adress} />
                </div>

                <div className="delivery-container" style={{ marginBottom: 10 }}>
                    <div className="fields-block">
                        <h4>{t('Facture_type')}</h4>
                        <div className="shipping">
                            <div>
                                <ul id="shipping_method" className="factura-type" style={{ display: "flex" }}>
                                    <li>
                                        <input
                                            type="radio"
                                            name="facturaType"
                                            checked={facturaType === "pf"}
                                            onChange={() => setFacturaType("pf")}
                                            id="pf"
                                        />
                                        <label htmlFor="pf">{t('Persona_fizica')}</label></li>
                                    <li>
                                        <input type="radio" name="facturaType"
                                            checked={facturaType === "firma"}
                                            onChange={() => setFacturaType("firma")}
                                            id="firma" />
                                        <label htmlFor="firma">{t('Your_firma')}</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {facturaType === "firma" && renderFirmaBlock()}
                {renderPFBlock()}

                {error && <div className="col-md-12 send-order-error-container">{error}</div>}
                <div className="col-md-12 outer-bottom-small m-t-20">
                    <button type="submit"
                        className="btn-upper btn btn-primary checkout-page-button"
                        disabled={!note || !phone || !name || isDisabled || !isValidEmail(email)}
                        onClick={() => setOrder()}
                    >
                        {t('Set_order')}
                    </button>
                </div>
            </div>
        )
    }

    const content = isLoading ? <Spinner /> : renderContent()

    return (
        <div className="login-form">
            {content}
        </div>
    )
}

const mapStateToProps = (state) => ({
    cartData: state.AddToCartReducer.cartData,
});

export default withRouter(connect(mapStateToProps, {
    getCartData,
    getCartInfoFirstLoad
})(DeliveryForm));
