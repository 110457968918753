import {
    CHANGE_LANGUAGE
} from "../actions/Actions";
import {getPrefLang, isPrefLangPresent} from "../services/Prefs";


const initialState = {
    lng: isPrefLangPresent() ? getPrefLang() : "ru",
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_LANGUAGE: {
            return Object.assign({}, state, {
                ...state,
                lng: action.payload,
            });
        }
        default:
            return state;
    }
}