import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from "react-router-dom"
import Header from "../../header";
import Footer from "../../footer";
//import HotDeals from "../widgets/hot-deals"
import axiosFetch from "../../../helpers/axios"
import RelatedProducts from "../widgets/related-products"
import { GET_PRODUCT_INFO_BY_ID, APP_TITLE } from "../../../helpers/constants"
import { showCurrencyPrice, setNameWithCurrentLang } from "../../../helpers/constants/functions"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Categories from "../widgets/categories"
import { NO_IMAGE_URL } from "../../../helpers/constants"
import Spinner from "../../../helpers/spinner"
import CookieHelper from "../../../helpers/cookie-helper";
import { addItemToMyCart, getCartData } from "../../../actions/AddToCartActionCreator"
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import './styles.scss'

function ProductDetails(props) {
  const { id } = useParams()
  const [productInfo, setProductInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const { t, lng, enqueueSnackbar, addItemToMyCart, getCartData, currency } = props
  const { nameRu, nameEn, nameDe, nameEsp, nameIt, price, oldPrice, descriptionRu, descriptionEn, descriptionDe, descriptionEsp, descriptionIt, images, category, subcategory, available, capacity } = productInfo
  const finalProductName = setNameWithCurrentLang(lng, nameRu, nameEn, nameDe, nameEsp, nameIt)

  const finalProductDescription = setNameWithCurrentLang(lng, descriptionRu, descriptionEn, descriptionDe, descriptionEsp, descriptionIt)


  const finalName = finalProductName ? finalProductName : t('Not_filled')

  document.title = `${APP_TITLE}${finalName}`

  useEffect(() => {
    const getProductDetails = () => {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("productId", id)

      const productResult = axiosFetch(GET_PRODUCT_INFO_BY_ID, formData)
      promiseResolved(productResult)
    }

    getProductDetails()
  }, [id])

  const promiseResolved = (productsResult) => {
    Promise.resolve(productsResult).then((pInfo) => {
      setProductInfo(pInfo[0])
      setIsLoading(false)
    })
  }

  const renderCarouselContent = (images) => {
    let content
    if (images.length > 0) {
      content = images.map((oneImage, index) => {
        const { imageName } = oneImage
        const imgUrl = imageName || NO_IMAGE_URL
        return (
          <div key={index}>
            <img src={imgUrl} alt="" />
          </div>
        )
      })
    } else {
      return (
        <div key={0}>
          <img src={NO_IMAGE_URL} alt="" />
        </div>
      )

    }


    return content
  }


  const addItemToCart = (id, finalName, price) => {
    setIsLoading(true)
    const token = CookieHelper("get", "token");
    setTimeout(() => {
      enqueueSnackbar(t('Been_added', { finalName }));
      const cartData = {
        id,
        finalName,
        count: 1,
        price,
        token
      }
      addItemToMyCart(cartData)
      setIsLoading(false)

      setTimeout(() => {
        getCartData(token)
      }, 1000);

    }, 500);
  }

  //sostav - это граммаж
  const isAvailable = Number(available) === 0 ? t('No') : t('Yes')

  const renderContent = () => {
    return (
      <>
        <div className="col-xs-12 col-sm-6 col-md-5 gallery-holder">

          <div className="product-item-holder size-big single-product-gallery small-gallery">

            <Carousel showArrows={true} emulateTouch={true} showIndicators={false} >
              {renderCarouselContent(images || [])}
            </Carousel>

          </div>
        </div>
        <div className='col-sm-6 col-md-7 product-info-block'>
          <div className="product-info">
            <h1 className="name">{finalName}</h1>

            <div className="stock-container info-container m-t-10">
              <div className="row">
                <div className="col-sm-6">
                  <div className="stock-box">
                    <span className="label">{t('Product_available')} :</span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="stock-box">
                    <span className="value">{isAvailable}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="stock-container info-container m-t-10">
              <div className="row">
                <div className="col-sm-6">
                  <div className="stock-box">
                    <span className="label">{t('Capacity')} :</span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="stock-box">
                    <span className="value">{capacity}ml</span>
                  </div>
                </div>
              </div>
            </div>


            {/* <div className="stock-container info-container m-t-10">
              <div className="row">
                <div className="col-sm-4">
                  <div className="stock-box">
                    <span className="label">{t('Grammage')} :</span>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="stock-box">
                    <span className="value">{sostav} {t('Gram')}</span>
                  </div>
                </div>
              </div>
            </div> */}

            {/* {count > 0 && <div className="stock-container info-container m-t-10">
              <div className="row">
                <div className="col-sm-4">
                  <div className="stock-box">
                    <span className="label">{t('Qty')}  :</span>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="stock-box">
                    <span className="value">{count} {t('Pieces')}.</span>
                  </div>
                </div>
              </div>
            </div>} */}

            <div className="description-container m-t-20">
              <div
                dangerouslySetInnerHTML={{
                  __html: finalProductDescription
                }} />
            </div>
            <div className="price-container info-container m-t-20">
              <div className="row">


                <div className="col-sm-12">
                  <div className="price-box">
                    <span className="price">{showCurrencyPrice(price, currency)} {currency}</span>
                    {oldPrice > 0 && <span className="price-strike">{showCurrencyPrice(oldPrice, currency)} {currency}</span>}
                  </div>
                </div>

              </div>
            </div>

            <div className="quantity-container info-container">
              <button
                className="btn btn-primary icon"
                data-toggle="dropdown"
                type="button"
                style={{ width: "100%", background: "#97bee2" }}
                disabled={Number(available) === 0}
                onClick={() => addItemToCart(id, finalName, price)}
              >
                {Number(available) === 0 ? t('Product_unavailable') : t('To_cart')}
              </button>
            </div>

          </div>
        </div>
      </>
    )
  }





  const content = isLoading ? <Spinner /> : renderContent()



  return (
    <div className="cnt-home">
      <Header />
      <div className="body-content outer-top-xs">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
              <Categories currentCategory={category} />
              {/* <HotDeals /> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-9 single-product">
              <div className="detail-block">
                <div className="row  wow fadeInUp">
                  {content}
                </div>
              </div>
              {category && <RelatedProducts subCategoryId={subcategory} category={category} />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({
  currency: state.MenuDataReducer.currency,
  lng: state.PrefsReducer.lng,
});
export default withRouter(compose(connect(mapStateToProps, {
  addItemToMyCart,
  getCartData
}), withNamespaces())(withSnackbar(ProductDetails)));
