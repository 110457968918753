import React, { useEffect, useState } from "react";
import Header from "../../header";
import Footer from "../../footer";
import Categories from "../widgets/categories";
import { setNameWithCurrentLang } from "../../../helpers/constants/functions"
import {
  PAGE_ABOUT_US_GET_CONTENT,
  //  ADD_PRODUCT
} from "../../../helpers/constants";
import axiosFetch from "../../../helpers/axios";
import Spinner from "../../../helpers/spinner";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./styles.scss";

// var xlsx = require("xlsx")

function About(props) {
  const { lng } = props
  const [isLoading, setIsLoading] = useState(false);
  const [aboutUsContent, setAboutUsContent] = useState();

  useEffect(() => {
    const getAboutUsContent = () => {
      setIsLoading(true);

      const aboutContent = axiosFetch(PAGE_ABOUT_US_GET_CONTENT);
      Promise.resolve(aboutContent).then((arrList) => {
        setAboutUsContent(arrList[0]);
        setIsLoading(false);
      });
    };

    getAboutUsContent();
  }, []);

  const renderAboutContent = () => {
    const { 
      titleRU,
      titleEN,
      titleDE,
      titleESP,
      titleIT,
      descriptionRU,
      image,
      descriptionEN,
      descriptionDE,
      descriptionESP,
      descriptionIT } = aboutUsContent;
    const finalTitle = setNameWithCurrentLang(lng, titleRU, titleEN, titleDE, titleESP, titleIT)
    const finalDescription = setNameWithCurrentLang(lng, descriptionRU, descriptionEN, descriptionDE, descriptionESP, descriptionIT)
    return (
      <>
        <div className="page-title">{finalTitle}</div>
        <img src={image} alt="ABout Us" className="about-us-image" />
        <div className="page-description"
          dangerouslySetInnerHTML={{
            __html: finalDescription
          }}></div>

      </>
    );
  };

  const content = isLoading ? (
    <Spinner />
  ) : aboutUsContent ? (
    renderAboutContent()
  ) : (
    ""
  );

  // function handleFile(e) {
  //   e.preventDefault()
  //   if (e.target.files) {

  //     const reader = new FileReader()

  //     reader.onload = (e) => {
  //       const data = e.target.result
  //       const workbook = xlsx.read(data, { type: "array" })
  //       // organize xlsx data into desired format


  //       const alph = [
  //         {
  //           key: "A",
  //           label: "code"
  //         },
  //         {
  //           key: "C",
  //           label: "price"
  //         },
  //         {
  //           key: "B",
  //           label: "description"
  //         },
  //       ];
  //       workbook.SheetNames.forEach((sheet) => {
  //         const worksheet = workbook.Sheets[sheet]
  //         const str = workbook.Sheets[sheet]['!ref']
  //         const totalItems = str.slice(str.indexOf('D') + 1);
  //         console.log("totalItems", Number(totalItems))
  //         let itemArr = {}
  //         for (let row = 1; row <= totalItems; row++) {

  //           alph.forEach((letter, index) => {
  //             const { key, label } = letter
  //             const cellValue = worksheet[`${key}${row}`]['v']
  //             let parfumeType
  //             let category
  //             let capacity
  //             if (label === "description") {
  //               if (cellValue.toLowerCase().includes("edt")) { parfumeType = "edt" }
  //               if (cellValue.toLowerCase().includes("edp")) { parfumeType = "edp" }
  //               if (cellValue.toLowerCase().includes("edc")) { parfumeType = "edc" }

  //               if (cellValue.toLowerCase().includes("women")) { category = 14 } else { category = 13 }


  //               const myArray = cellValue.toLowerCase().split(" ");

  //               capacity = myArray.find(word => word.includes("ml")) || "50ml"
  //             }


  //             itemArr = {
  //               ...itemArr,
  //               [row]: {
  //                 ...itemArr[row],
  //                 [label]: cellValue,
  //                 parfumeType,
  //                 category,
  //                 capacity
  //               }
  //             }

  //           })

  //           const { capacity, category, code, description, parfumeType, price } = itemArr[row]
  //           const formData = new FormData()
  //           formData.append("capacity", capacity)
  //           formData.append("category", category)
  //           formData.append("price", price)
  //           formData.append("code", code)
  //           formData.append("parfumeType", parfumeType)
  //           formData.append("description", description)

  //           axiosFetch(ADD_PRODUCT, formData)

  //         }
  //       })

  //     }
  //     reader.readAsArrayBuffer(e.target.files[0])

  //   }

  // }

  return (
    <div className="cnt-home">
      <Header />
      <div className="body-content outer-top-xs">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
              <Categories />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-9 homebanner-holder">
              {/* <input type="file" onChange={handleFile}></input> */}
              {content}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}


const mapStateToProps = (state) => ({
  lng: state.PrefsReducer.lng,
});

export default withRouter(
  connect(mapStateToProps, {})(About)
);

