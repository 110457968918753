import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import classNames from 'classnames'
import { setNameWithCurrentLang } from "../../../../helpers/constants/functions"

import "./styles.scss";

function Categories(props) {
  const {
    menuData: { menu },
    currentCategory,
    lng,
    isHomePage = false
  } = props;

  const renderCategories = () => {
    const content = menu.map((menuItem, index) => {
      const { nameRu, url, id, nameEn, nameDe, nameEsp, nameIt, categoryImage } = menuItem;
      const activeClass = currentCategory === id ? "active-category" : ""
      const finalTitle = setNameWithCurrentLang(lng, nameRu, nameEn, nameDe, nameEsp, nameIt)

      return (
        <li
          //className={`dropdown menu-item `}
          className={classNames(`${activeClass}`, {
            'dropdown': true,
            'menu-item': true,
            'show-icons': isHomePage
          })}
          key={index} >
          {isHomePage ?
            <Link to={url}>
              <div className="category-image" style={{ backgroundImage: `url(${categoryImage})` }}>
                {/* <img src={categoryImage} className={`category-image ${activeClass}`} alt={finalTitle} /> */}
                <div className="category-title">
                  {finalTitle}
                </div>
              </div>
            </Link>

            :
            <Link to={url}>
              {finalTitle}
            </Link>
          }


        </li>
      );
    });

    return content;
  };

  return (
    <div className="side-menu ">
      <nav className="yamm " role="navigation">
        <ul
          className={classNames({
            'nav asd': true,
            'd-flex': isHomePage
          })}>{menu && renderCategories()}</ul>

      </nav>
    </div>
  );
}

const mapStateToProps = (state) => ({
  menuData: state.MenuDataReducer,
  lng: state.PrefsReducer.lng,
});

export default withRouter(connect(mapStateToProps, {})(Categories));
