import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { setGlobalCurrency, changeLanguage } from "../../../actions/MenuDataActionCreators"
import { setLoginAuth } from "../../../actions/SetLoginAuthActionCreators"
import { withNamespaces } from "react-i18next";
import langRu from "../../../images/ru.png"
import langEn from "../../../images/en.png"
import langDe from "../../../images/de.png"
import langEsp from "../../../images/esp.png"
import langIt from "../../../images/it.png"
// import langRo from "../../../images/md.png"
//import Button from '../../../helpers/button'
import SearchField from "../header-middle-bar/search-field"
import logo from "../../../images/logo.svg";
import phone from "../../../images/phn.svg";
// import ruble from "../../../images/ruble.svg";
// import euro from "../../../images/euro.svg";

import './styles.scss'

function HeaderTopBar(props) {
  const { setGlobalCurrency, currency, changeLanguage, lng, t, contacts } = props
  //const [showTheme, setShowtheme] = useState(true)

  // const renderLoginInfo = () => {
  //   const { name } = loginData

  //   return (
  //     <span style={{ color: "#fff" }}>
  //       <Link to="/profile">
  //         {name}
  //       </Link>
  //       <b className="exit-btn" onClick={() => exit()}> ({t('Exit')}) </b>
  //     </span>
  //   )
  // }

  // const renderAuthLink = () => {
  //   return (
  //     <Link to="/auth">
  //       <i className="icon fa fa-lock"></i>{t('Autorization')}
  //     </Link>
  //   )
  // }

  // const exit = () => {
  //   CookieHelper('remove', 'userEmail');
  //   CookieHelper('remove', 'userName');
  //   const data = {}
  //   setLoginAuth(data)
  // }

  // const themesStyles = () => {
  //   if(showTheme){
  //     document.getElementById("html").classList.add('white-theme');
  //   }else{
  //     document.getElementById("html").classList.remove('white-theme');
  //   }

  //   setShowtheme(!showTheme)
  // }


  // const renderMenuItems = (menu) => {
  //   const menuContent = menu.map((menuItem, index) => {
  //     const { name, subCategories, label, labelColor, categoryImage, url, nameRo, labelRo } = menuItem
  //     const finalName = lng === "en" ? name : nameRo
  //     const finalLabel = lng === "en" ? label : labelRo
  //     return (
  //       <li className="mega-menu" key={index}>

  //         <Link to={url} >

  //           {finalName}

  //           {finalLabel &&
  //             <span className="menu-label hot-menu hidden-xs" style={{ backgroundColor: labelColor }}>
  //               {finalLabel}
  //             </span>
  //           }
  //         </Link>
  //         {/* {subCategories.length > 0 && renderSubcategoryMenu(subCategories, categoryImage)} */}

  //       </li>
  //     )
  //   })

  //   return menuContent
  // }



  const renderCurrentLang = () => {
    let currentLang

    switch (lng) {
      case "de":
        currentLang = langDe
        break;
      case "ru":
        currentLang = langRu
        break;
      case "esp":
        currentLang = langEsp
        break;
      case "it":
        currentLang = langIt
        break;

      default:
        currentLang = langEn
        break;
    }

    return currentLang
  }

  const renderAdaptiveHeader = () => {
    return (
      <div className="header-for-mobile">
        <div className="cnt-account">
          <div className="header-top-nav">
            <ul className="header-nav-list">
              <li>
                <Link to={"/about"}>{t("About_us")}</Link>
              </li>
              <li>
                <Link to={"/contacts"}>{t("Contacts")}</Link>
              </li>
            </ul>
          </div>
          <div className="header-phone">
            <div className="header-phone-icon">
              <img src={phone} alt="icon" style={{ width: 20 }} />
            </div>

            <div className="header-phone-text">
              <a href={`tel:${contacts.phone}`} className="number"><span className="subnumber">{contacts.phone}</span></a>
            </div>
          </div>

        </div>

        <div className="cnt-block">
          <ul className="list-unstyled list-inline">
            {/* <li>
            <Button
              type="button"
              className="btn-upper btn btn-primary checkout-page-button"
              text={showTheme ? 'Показать светлую тему' : 'Показать тёмную тему'}
             // disabled={!login || !password}
              onClick={() => themesStyles()}
            />
          </li> */}
            <li className="dropdown dropdown-small">
              <a
                href="/"
                className="dropdown-toggle"
                data-hover="dropdown"
                data-toggle="dropdown"
              >
                <span className="value">{currency} </span>
                <b className="caret"></b>
              </a>
              <ul className="dropdown-menu currency-menu">
                <li>
                  <span onClick={() => setGlobalCurrency("$")}>$</span>
                </li>
                <li>
                  <span onClick={() => setGlobalCurrency("€")}>€</span>
                </li>
                <li>
                  <span onClick={() => setGlobalCurrency("₽")}>₽</span>
                </li>
              </ul>
            </li>

            <li className="dropdown dropdown-small languages-container">
              <a
                href="/"
                className="dropdown-toggle"
                data-hover="dropdown"
                data-toggle="dropdown"
              >
                <span className="value"><img src={renderCurrentLang()} alt={renderCurrentLang()} /> </span>
                <b className="caret"></b>
              </a>
              <ul className="dropdown-menu languages">
                <li onClick={() => changeLanguage("en")}>
                  <span><img src={langEn} alt={langEn} /></span>
                </li>
                <li onClick={() => changeLanguage("ru")}>
                  <span><img src={langRu} alt={langRu} /></span>
                </li>
                <li onClick={() => changeLanguage("de")}>
                  <span><img src={langDe} alt={langDe} /></span>
                </li>
                <li onClick={() => changeLanguage("esp")}>
                  <span><img src={langEsp} alt={langEsp} /></span>
                </li>
                <li onClick={() => changeLanguage("it")}>
                  <span><img src={langIt} alt={langIt} /></span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

    )
  }

  return (
    <div className="top-bar animate-dropdown">
      <div className="container top-header">
        <div className="for-adaptive-styles">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
          {/* ADAPTIVE HEADER */}
          {/* {renderAdaptiveHeader()} */}
          {/* ADAPTIVE HEADER */}
        </div>


        <div className="header-top-inner main-header">
          <SearchField {...{ t }} />
          <div className="cnt-account">
            {/* <div className="header-top-nav">
              <ul className="header-nav-list">
                <li>
                  <Link to={"/about"}>{t("About_us")}</Link>
                </li>
                <li>
                  <Link to={"/contacts"}>{t("Contacts")}</Link>
                </li>
              </ul>
            </div> */}
            <div className="header-phone">
              <div className="header-phone-icon">
                <img src={phone} alt="icon" style={{ width: 20 }} />
              </div>

              <div className="header-phone-text">
                <a href={`tel:${contacts.phone}`} className="number"><span className="subnumber">{contacts.phone}</span></a>
              </div>
            </div>

          </div>

          <div className="cnt-block">
            <ul className="list-unstyled list-inline">
              {/* <li>
                <Button
                  type="button"
                  className="btn-upper btn btn-primary checkout-page-button"
                  text={showTheme ? 'Показать светлую тему' : 'Показать тёмную тему'}
                 // disabled={!login || !password}
                  onClick={() => themesStyles()}
                />
              </li> */}
              <li className="dropdown dropdown-small">
                <a
                  href="/"
                  className="dropdown-toggle"
                  data-hover="dropdown"
                  data-toggle="dropdown"
                >
                  <span className="value">{currency} </span>
                  <b className="caret"></b>
                </a>
                <ul className="dropdown-menu currency-menu">
                  <li>
                    <span onClick={() => setGlobalCurrency("$")}>$</span>
                  </li>
                  <li>
                    <span onClick={() => setGlobalCurrency("€")}>€</span>
                  </li>
                  <li>
                    <span onClick={() => setGlobalCurrency("₽")}>₽</span>
                  </li>
                </ul>
              </li>

              <li className="dropdown dropdown-small languages-container">
                <a
                  href="/"
                  className="dropdown-toggle"
                  data-hover="dropdown"
                  data-toggle="dropdown"
                >
                  <span className="value"><img src={renderCurrentLang()} alt={renderCurrentLang()} /> </span>
                  <b className="caret"></b>
                </a>
                <ul className="dropdown-menu languages">
                  <li onClick={() => changeLanguage("en")}>
                    <span><img src={langEn} alt={langEn} /></span>
                  </li>
                  <li onClick={() => changeLanguage("ru")}>
                    <span><img src={langRu} alt={langRu} /></span>
                  </li>
                  <li onClick={() => changeLanguage("de")}>
                    <span><img src={langDe} alt={langDe} /></span>
                  </li>
                  <li onClick={() => changeLanguage("esp")}>
                    <span><img src={langEsp} alt={langEsp} /></span>
                  </li>
                  <li onClick={() => changeLanguage("it")}>
                    <span><img src={langIt} alt={langIt} /></span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  currency: state.MenuDataReducer.currency,
  menuData: state.MenuDataReducer,
  lng: state.PrefsReducer.lng,
  loginData: state.SetLoginReducer.loginData,
  contacts: state.AddToCartReducer.contacts,
});

export default withRouter(compose(connect(mapStateToProps, {
  setGlobalCurrency,
  setLoginAuth,
  changeLanguage
}), withNamespaces())(HeaderTopBar));

