// import { USD_VALUE, EURO_VALUE } from "../../constants"

export const isValidEmail = (email) => {
  const regExp = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(email);
}

export const amountFormatter = (number) => Number(number).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$& ');

export const showCurrencyPrice = (finalPrice, inCurrency) => {
  let price
  switch (inCurrency) {
    case "€":
      price = (finalPrice * 0.95).toFixed(0)
      break;
    case "₽":
      price = (finalPrice * 100)
      break;

    default:
      price = finalPrice
      break;
  }
  return price
}
export const changePriceToUsd = (value, inCurrency) => {
  let price
  switch (inCurrency) {
    case "€":
      price = (value / 0.95)
      break;
    case "₽":
      price = (value / 100)
      break;

    default:
      price = value
      break;
  }
  return price
}
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const setNameWithCurrentLang = (currLang, textRu, textEn, textDe, textEsp, textIt) => {
  let currentLang

  switch (currLang) {
    case "de":
      currentLang = textDe
      break;
    case "ru":
      currentLang = textRu
      break;
    case "esp":
      currentLang = textEsp
      break;
    case "it":
      currentLang = textIt
      break;

    default:
      currentLang = textEn
      break;
  }

  return currentLang
}