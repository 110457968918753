import React, { useState } from "react";
import {
  SALES,
  RECOMAND,
  WEEK,
  NEW,
  DISCOUNT,
  NO_IMAGE_URL
} from "../../../../helpers/constants";
import { showCurrencyPrice, setNameWithCurrentLang } from "../../../../helpers/constants/functions";
import axiosFetch from "../../../../helpers/axios";
import { CART_UPDATE_ITEM_COUNT } from "../../../../helpers/constants";
import { withSnackbar } from 'notistack';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { RemoveIcon } from "../../../../helpers/icons/icon-remove"
import { ArrowIcon } from "../../../../helpers/icons/icon-arrow"
// import { RemoveIcon } from "../../"

import "./styles.scss";

function CartTable(props) {
  const { cartItems, currency, deleteItemFromCart, refreshData, token, enqueueSnackbar, t, lng } = props;
  const [deleteProductId, setDeleteProduct] = useState(0);

  const showDeleteProductForm = () => {
    return (
      <div className="cart-review-delete-container">
        <span>{t('Delete')}?</span>
        <button
          className="btn btn-primary btn-upper"
          onClick={() => deleteItemFromCart(deleteProductId)}
        >
          OK
        </button>
        <button
          className="btn btn-primary btn-upper"
          onClick={() => setDeleteProduct()}
        >
          {t('Cancel')}
        </button>
      </div>
    );
  };

  const switchLabels = (label) => {
    let labelClass;

    switch (label) {
      case SALES:
        labelClass = "sales";
        break;
      case RECOMAND:
        labelClass = "recomand";
        break;
      case WEEK:
        labelClass = "week";
        break;
      case NEW:
        labelClass = "new";
        break;
      case DISCOUNT:
        labelClass = "discount";
        break;

      default:
        break;
    }
    return labelClass;
  };

  const renderLabel = (labels) => {
    const content = labels.map((labels, index) => {
      const { labelName } = labels;
      const labelClass = switchLabels(labelName);

      return (
        <div className={`tag ${labelClass}`} title={labelName} key={index} />
      );
    });

    return content;
  };

  const changeCount = (lineId, newCount) => {

    const formData = new FormData();
    formData.append("lineId", lineId);
    formData.append("count", newCount);
    formData.append("token", token);
    axiosFetch(CART_UPDATE_ITEM_COUNT, formData).then(() => {
      refreshData();
    });
  }

  const changeProductCount = (count, lineId, upOrDown = "down") => {
    let newCount;
    if (upOrDown === "up") {
      newCount = parseInt(count) + 1;
      changeCount(lineId, newCount)
    } else if (count > 1) {
      newCount = parseInt(count) - 1;
      changeCount(lineId, newCount)
    } else {
      enqueueSnackbar(t('Cannot_be_less'));
    }
  };

  const renderCartItemsTable = () => {

    const content = (cartItems || []).map((cartItem, index) => {
      const { nameRu, nameEn, nameDe, nameEsp, nameIt, labels, count, id, image, price, cartLineId } = cartItem;
      const finalProductName = setNameWithCurrentLang(lng, nameRu, nameEn, nameDe, nameEsp, nameIt,)
      return (
        <tr key={index}>
          <td className="romove-item">
            {deleteProductId === cartLineId && showDeleteProductForm()}
            <div className="icon">
              <RemoveIcon onClick={() => setDeleteProduct(cartLineId)} />

            </div>
          </td>
          <td className="cart-image">
            <Link to={`/details/${id}`}>
              <div
                className="product-image-container"
                style={{ backgroundImage: `url(${image || NO_IMAGE_URL})` }}
              />
            </Link>

            {/* <a className="entry-thumbnail" href="detail.html">
                            <img src="assets/images/products/p1.jpg" alt="" />
                        </a> */}
          </td>
          <td className="cart-product-name-info">
            <h4 className="cart-product-description">
              <Link to={`/details/${id}`}>{finalProductName}</Link>
            </h4>
            <div className="row">{labels && renderLabel(labels)}</div>
          </td>
          {/* <td className="cart-product-sub-total">
            <span className="cart-sub-total-price">
              {productCount}
            </span>
          </td> */}

          <td className="cart-product-quantity">
            <div className="quant-input">
              <div className="arrows">
                <div
                  className="arrow plus gradient"
                  onClick={() => changeProductCount(count, cartLineId, "up")}
                >
                  <span className="ir">
                    {/* <i className="icon fa fa-sort-asc"></i> */}
                    <ArrowIcon className="up arrow-svg"/>
                  </span>
                </div>
                <div className="arrow minus gradient"
                  onClick={() => changeProductCount(count, cartLineId)}
                >
                  <span className="ir">
                  <ArrowIcon className="down arrow-svg"/>
                  </span>
                </div>
              </div>
              <input type="text" value={count} />
            </div>
          </td>
          <td className="cart-product-sub-total">
            <span className="cart-sub-total-price">
              {showCurrencyPrice(price, currency)} {currency}
            </span>
          </td>
          <td className="cart-product-sub-total">
            <span className="cart-grand-total-price">
              {showCurrencyPrice(price * count, currency)} {currency}
            </span>
          </td>
        </tr>
      );
    });

    return content;
  };

  return (
    <div className="shopping-cart-table ">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th className="cart-romove item">{t('Table_cart_delete')}</th>
              <th className="cart-description item">{t('Table_cart_image')}</th>
              <th className="cart-product-name item">{t('Table_cart_product_name')}</th>
              <th className="cart-product-name item">{t('Table_cart_qty')}</th>

              {/* <th className="cart-qty item">{t('Table_cart_qty_items')}</th> */}
              <th className="cart-sub-total item">{t('Table_cart_product_price')}</th>
              <th className="cart-total last-item">{t('Total')}</th>
            </tr>
          </thead>
          <tbody>{cartItems.length > 0 && renderCartItemsTable()}</tbody>
        </table>
      </div>
    </div>
  );
}

export default withRouter(connect(null, {})(withSnackbar(CartTable)));
