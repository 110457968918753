import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Spinner from "../../../../helpers/spinner";
import axiosFetch from "../../../../helpers/axios";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  setGlobalSearchCategory,
  setGlobalSearchText,
  setGlobalLiveSearchResult,
  hideLiveSearchContainer
} from "../../../../actions/MenuDataActionCreators";
import classNames from 'classnames'
import { GET_ALL_PRODUCTS_BY_SEARCH_TEXT, NO_IMAGE_URL } from "../../../../helpers/constants";
import { setNameWithCurrentLang, showCurrencyPrice, capitalizeFirstLetter } from "../../../../helpers/constants/functions"
import search from "../../../../images/search.svg";
import "./styles.scss";
import closeIcon from "../../../../images/close.png";
import { CloseRemoveIcon } from "../../../../images/icons/icon-close-remove"

function SearchField(props) {
  const [categoryInField, setSearchCategory] = useState({});
  const [isRedirect, setIsRedirect] = useState(false);
  const [searchResult, setSearchResult] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const {
    menuData: { menu },
    //searchCategory,
    setGlobalSearchCategory,
    setGlobalSearchText,
    searchText,
    t,
    lng,
    // globalSearchResult,
    setGlobalLiveSearchResult,
    showGlobalSearchContainer,
    hideLiveSearchContainer,
    currency
  } = props;

  useEffect(() => {
    const getProductsList = () => {
      //const productsResult = axiosFetch(GET_ALL_PRODUCTS);
      promiseResolved([]);
    };

    const promiseResolved = (productsResult) => {
      Promise.resolve(productsResult).then((pInfo) => {
        setGlobalLiveSearchResult(pInfo);
      });
    };

    //globalSearchResult?.length === 0 &&
    getProductsList()
    promiseResolved()
  }, [setGlobalLiveSearchResult]);

  const onChangeSeelctedSearchCategory = (id, nameRu, nameEn, nameDe, nameEsp, nameIt) => {
    setSearchCategory({ id, nameRu, nameEn, nameDe, nameEsp, nameIt });
    searchProducts()
  }

  const renderSearchMenuItems = () => {
    const searchMenu = menu.map((menuItem, index) => {
      const { nameRu, nameEn, nameDe, nameEsp, nameIt, id } = menuItem;

      const finalName = setNameWithCurrentLang(lng, nameRu, nameEn, nameDe, nameEsp, nameIt)

      return (
        <li
          className="menu-header"
          key={index}
          onClick={() => onChangeSeelctedSearchCategory(id, nameRu, nameEn, nameDe, nameEsp, nameIt)}
        >
          {finalName}
        </li>
      );
    });
    return searchMenu;
  };

  const redirectToSearchPage = (text) => {
    if (text) {
      setGlobalSearchCategory(categoryInField);
      hideLiveSearchContainer()
      setIsRedirect(true);
    }
  };

  const promiseResolved = (productsResult) => {
    Promise.resolve(productsResult).then((pInfo) => {
      setSearchResult(pInfo)
    })

  }

  const searchProducts = (value) => {
    const formData = new FormData()
      formData.append("searchText", value)
      formData.append("searchCategoryName", categoryInField.nameRu)
      formData.append("searchCategoryId", categoryInField.id)
      //formData.append("currentPage", page)
      //formData.append("itemsOnPage", ITEMS_ON_THE_PAGE)
      formData.append("minValue", 0)
      formData.append("maxValue", 10000)
      const productsResult = axiosFetch(GET_ALL_PRODUCTS_BY_SEARCH_TEXT, formData);
      promiseResolved(productsResult)
  }

  const setText = (e) => {
    if (e.keyCode === 13) {
      hideLiveSearchContainer()
      redirectToSearchPage(searchText);
    }
    if (searchText.length >= 2) {
      searchProducts(e.target.value)
    }
  };

  const emptyContent = () => {
    return (
      <div className="live-search-not-found">{t('Products_not_found')}</div>
    )
  }

  const liveContent = (productsList) => {

    if (productsList?.length === 0) {
      return emptyContent()
    }
    const content = (productsList || []).map((searchLine, index) => {
      const { nameRu, nameEn, nameDe, nameEsp, nameIt, image, price, id } = searchLine
      // const finalName = lng === "en" ? nameRu : nameRo;
      const finalName = setNameWithCurrentLang(lng, nameRu, nameEn, nameDe, nameEsp, nameIt)
      const imgUrl = image || NO_IMAGE_URL
      return (
        <div className="search-line" key={index} >
          <Link to={`/details/${id}`} onClick={() => onBlur()}>
            <img src={imgUrl} alt={finalName} />
            <div className="live-search-product">{finalName}</div>
            <div className="live-search-price">{showCurrencyPrice(price, currency)} {currency}</div>
          </Link>
        </div>
      );
    });
    return content
  };

  const searchTextFilter = (items, text) => {
    const cell = `name${capitalizeFirstLetter(lng)}`
    const response = items.filter(item => {

      return item[cell].toLowerCase().indexOf(text.toLowerCase()) > -1
    })

    return response

  };

  const renderLiveSearchContainer = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    const visibleItems =
      searchResult && searchResult.length > 0 ?
        searchTextFilter(searchResult, searchText) : [];
    // console.log("visibleItems", visibleItems);
    const content = isLoading ? <Spinner /> : showGlobalSearchContainer && liveContent(visibleItems);

    return <div
      className={classNames({
        'live-search': true,
        'empty-container': !showGlobalSearchContainer
      })}
    >
      {content}
    </div>;
  };

  const onBlur = () => {
    setTimeout(() => {
      hideLiveSearchContainer()
    }, 300);

  }

  const finalSearchCategoryName = setNameWithCurrentLang(lng, categoryInField.nameRu, categoryInField.nameEn, categoryInField.nameDe, categoryInField.nameEsp, categoryInField.nameIt)


  return (
    <div className="col-xs-12 col-sm-12 col-md-6 top-search-holder">
      {isRedirect && <Redirect to="/search" />}
      <div className="search-area">
        <div className="control-group">
          <ul className="categories-filter animate-dropdown">
            <li className="dropdown">
              <a
                className="dropdown-toggle selected-category"
                data-toggle="dropdown"
                href="/"
              >
                {finalSearchCategoryName}
                <b className="caret" style={{ marginTop: 0}}></b>
              </a>

              <ul className="dropdown-menu" role="menu">
                {menu && renderSearchMenuItems()}
                <li
                  className="menu-header"
                  key={-1}
                  onClick={() =>
                    setSearchCategory({
                      id: 0,
                      nameRu: "По всему сайту",
                      nameEn: "To all site",
                      nameDe: "To all site",
                      nameEsp: "To all site",
                      nameIt: "To all site",
                    })
                  }
                >
                  {t("Search_on_all_site")}
                </li>
              </ul>
            </li>
          </ul>
          <div className="search-container">
            <input
              className="search-field"
              placeholder={t("Search_products")}
              onChange={(e) => setGlobalSearchText(e.target.value)}
              // onChange={(e) => setGlobalSearchText(e.target.value)}
              onKeyDown={(e) => setText(e)}
              value={searchText || ""}
            //onBlur={() => onBlur()}
            />
            {searchText && searchText.length > 2 && <div className="close-search" onClick={() => setGlobalSearchText("")}>
              <CloseRemoveIcon />
      
            </div>}
            {/* <Link to={`/search`}> */}
            <div
              className="search-button"
              onClick={() => redirectToSearchPage(searchText)}
            >
               <img src={search} alt="" />
            </div>
            {/* </Link> */}

            {searchText && searchText.length > 2 && renderLiveSearchContainer()}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  menuData: state.MenuDataReducer,
  searchCategory: state.MenuDataReducer.searchCategory,
  globalSearchResult: state.MenuDataReducer.globalSearchResult,
  showGlobalSearchContainer: state.MenuDataReducer.showGlobalSearchContainer,
  searchText: state.MenuDataReducer.searchText,
  lng: state.PrefsReducer.lng,
  currency: state.MenuDataReducer.currency,
});

export default withRouter(
  connect(mapStateToProps, {
    setGlobalSearchCategory,
    setGlobalSearchText,
    setGlobalLiveSearchResult,
    hideLiveSearchContainer
  })(SearchField)
);
