import React, { useState, useEffect } from 'react';
import Spinner from "../../../../helpers/spinner"
import { USER_FB_LOGIN, USER_LOGIN } from '../../../../helpers/constants'
import Input from '../../../../helpers/input'
import Button from '../../../../helpers/button'
import axiosFetch from "../../../../helpers/axios"
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { setLoginAuth } from "../../../../actions/SetLoginAuthActionCreators"
import { isValidEmail } from "../../../../helpers/constants/functions"
import CookieHelper from "../../../../helpers/cookie-helper";
import FacebookLogin from 'react-facebook-login';
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import './styles.scss'

function LoginForm(props) {

    const { setLoginAuth, t, lng } = props

    const [loginData, setLoginData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")

    const [isRedirect, setIsRedirect] = useState(false)

    useEffect(() => {
        const userEmail = CookieHelper("get", "userEmail");
        if (userEmail) {
            setIsRedirect(true)
        }
    }, [])

    const setValue = (param, value) => {
        setError()
        const data = {
            ...loginData,
            [param]: value
        }
        setLoginData(data)
    }

    const enter = () => {
        setIsLoading(true)
        const { login, password } = loginData
        const email = isValidEmail(login)
        if (!email) {
            setError(t('Email_error'))
            setIsLoading(false)
        } else {

            setTimeout(() => {
                userCheckAuth(login, password)
            }, 500);
        }
    }

    const userCheckAuth = (email, password) => {

        const formData = new FormData()
        formData.append("email", email)
        formData.append("password", password)

        const response = axiosFetch(USER_LOGIN, formData)
        promiseResolved(response)

    }

    const promiseResolved = (response) => {
        Promise.resolve(response).then((resArr) => {
            if (resArr.error) {
                const finalTitle = lng === "en" ? resArr.error : resArr.errorRo
                setError(finalTitle)
            } else {
                const { userInfo: { userName, userEmail, userPicture, userId } } = resArr

                CookieHelper("set", "userEmail", userEmail);
                CookieHelper("set", "userName", userName);

                const loginDataObj = {
                    name: userName,
                    email: userEmail,
                    picture: userPicture,
                    id: userId
                }

                setLoginAuth(loginDataObj)
                setIsRedirect(true)
            }
        })
        setIsLoading(false)
    }

    const componentClicked = () => {
        setIsLoading(true)
    }

    const responseFacebook = (response) => {

        if (!response.status) {
            const { email, name, id, picture: { data: { url } } } = response

            CookieHelper("set", "userEmail", email);
            CookieHelper("set", "userName", name);

            const formData = new FormData()
            formData.append("email", email)
            formData.append("name", name)
            formData.append("fbId", id)
            formData.append("picture", url)

            axiosFetch(USER_FB_LOGIN, formData)

            const loginDataObj = {
                name,
                email,
                picture: url
            }

            setLoginAuth(loginDataObj)
            setIsRedirect(true)
        }
        setIsLoading(false)
    }


    const renderContent = () => {
        return (
            <div>
                {isRedirect && <Redirect to="/" />}
                <div className="social-sign-in outer-top-xs">

                    <FacebookLogin
                        appId="2580449988925310"
                        autoLoad={false}
                        textButton={t('Enter_with_facebook')}
                        fields="name,email,picture"
                        onClick={componentClicked}
                        callback={responseFacebook} />
                </div>
                <div className="form-group">
                    <label className="info-title" htmlFor="exampleInputEmail1">{t('Your_email')} <span>*</span></label>
                    <Input
                        type="email"
                        id="register-login"
                        value={login}
                        className="form-control unicase-form-control text-input"
                        onChange={(e) => setValue("login", e.target.value)}
                    />

                </div>
                <div className="form-group">
                    <label className="info-title" htmlFor="exampleInputPassword1">{t('Your_password')} <span>*</span></label>

                    <Input
                        type="password"
                        value={password}
                        id="register-password"
                        className="form-control unicase-form-control text-input"
                        onChange={(e) => setValue("password", e.target.value)}
                    />

                </div>
                <div className="radio outer-xs">

                    {/* <a href="/" className="forgot-password pull-right">Забыли пароль?</a> */}
                </div>
                {error && <div className="error-container">{error}</div>}
                <Button
                    type="button"
                    className="btn-upper btn btn-primary checkout-page-button"
                    text={t('Enter')}
                    disabled={!login || !password}
                    onClick={() => enter()}
                />
            </div>
        )
    }

    const { login, password } = loginData
    const content = isLoading ? <Spinner /> : renderContent()

    return (
        <div className="login-form">
            {content}
        </div>
    )
}

const mapStateToProps = (state) => ({
    lng: state.PrefsReducer.lng,
});

export default withRouter(compose(connect(mapStateToProps, {
    setLoginAuth,
}),withNamespaces())(LoginForm));

