import React from "react";
import Header from "../../header";
// import Categories from "../widgets/categories"
// import HotDeals from "../widgets/hot-deals"
// import Tags from "../widgets/tags"
// import SpecialDeal from "../widgets/special-deal"
// import NewsLatter from "../widgets/newslatter"
// import Testimonials from "../widgets/testimonials"
import Slider from "../widgets/slider"
//import NewProducts from "../widgets/new-products"
import FeaturedProducts from "../widgets/featured-products"
//import News from "./news"
//import Banner from "../widgets/banner"
//import BestSeller from "../widgets/best-seller"
//import LatestFromBlog from "../widgets/latest-from-blog"
//import NewArrivals from "../widgets/new-arrivals"
//import BrandsCarousel from "../widgets/brands-carousel"
import Footer from "../../footer"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import CategoryCarousel from "./category-carousel"
import { setNameWithCurrentLang } from "../../../helpers/constants/functions"

import "./styles.scss"
function HomePage(props) {

  const { menuData: { menu }, lng } = props



  const renderCategoriesContent = () => {

    const content = (menu || []).map((item, i) => {
      // console.log("item", item)
      const { id, nameDe, nameEn, nameEsp, nameIt, nameRu } = item
      const finalName = setNameWithCurrentLang(lng, nameRu, nameEn, nameDe, nameEsp, nameIt)

      return <CategoryCarousel {...{ id, finalName }} />
    })
    return content
  }



  return (
    <div className="cnt-home">
      <Header />
      <div className="body-content outer-top-xs" id="top-banner-and-menu">
        <div className="container home-page">

          <div className="row ">
            <Slider />
            {renderCategoriesContent()}
            <div className="col-xs-12 col-sm-12 col-md-12 homebanner-holder">
              <FeaturedProducts />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );

}


const mapStateToProps = (state) => ({
  menuData: state.MenuDataReducer,
  lng: state.PrefsReducer.lng,
  globalSearchResult: state.MenuDataReducer.globalSearchResult,
});

export default withRouter(
  compose(
    connect(mapStateToProps, {}),
    withNamespaces()
  )(HomePage)
);