import translationDE from "./translate/de/languages";
import translationRU from "./translate/ru/languages";
import translationEN from "./translate/en/languages";
import translationESP from "./translate/esp/languages";
import translationIT from "./translate/it/languages";
import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import {getPrefLang, isPrefLangPresent} from "./services/Prefs";

// the translations
const resources = {
    de: {
        translation: translationDE
    },
    ru: {
        translation: translationRU
    },
    esp: {
        translation: translationESP
    },
    en: {
        translation: translationEN
    },
    it: {
        translation: translationIT
    }
};

i18n.use(reactI18nextModule)
    .init({
        resources,
        lng: isPrefLangPresent() ? getPrefLang() : "ru",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
