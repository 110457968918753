import React, { Component } from "react";
import Header from "../../header";
import Categories from "../widgets/categories"
import News from "../homepage/news"
import Footer from "../../footer"

import "./styles.scss"
export default class HomePage extends Component {
  render() {
    return (
      <div className="cnt-home">
        <Header />
        <div className="body-content outer-top-xs" >
          <div className="container blog-page news-page">
            <div className="row news-page">
              <div className="col-xs-2 col-sm-3 col-md-3 sidebar">
                <Categories />
              </div>
              <div className="col-xs-10 col-sm-9 col-md-9 ">
                <News />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
