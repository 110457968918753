
import React from 'react';

export const RemoveIcon = ({ onClick }) => {
    return (
        <svg onClick={onClick} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00004 7.93333L8.93337 9.86666C9.0556 9.98888 9.21115 10.05 9.40004 10.05C9.58893 10.05 9.74448 9.98888 9.86671 9.86666C9.98893 9.74444 10.05 9.58888 10.05 9.39999C10.05 9.21111 9.98893 9.05555 9.86671 8.93333L7.93337 6.99999L9.86671 5.06666C9.98893 4.94444 10.05 4.78888 10.05 4.59999C10.05 4.41111 9.98893 4.25555 9.86671 4.13333C9.74448 4.01111 9.58893 3.94999 9.40004 3.94999C9.21115 3.94999 9.0556 4.01111 8.93337 4.13333L7.00004 6.06666L5.06671 4.13333C4.94448 4.01111 4.78893 3.94999 4.60004 3.94999C4.41115 3.94999 4.2556 4.01111 4.13337 4.13333C4.01115 4.25555 3.95004 4.41111 3.95004 4.59999C3.95004 4.78888 4.01115 4.94444 4.13337 5.06666L6.06671 6.99999L4.13337 8.93333C4.01115 9.05555 3.95004 9.21111 3.95004 9.39999C3.95004 9.58888 4.01115 9.74444 4.13337 9.86666C4.2556 9.98888 4.41115 10.05 4.60004 10.05C4.78893 10.05 4.94448 9.98888 5.06671 9.86666L7.00004 7.93333ZM7.00004 13.6667C6.07782 13.6667 5.21115 13.4917 4.40004 13.1417C3.58893 12.7917 2.88337 12.3167 2.28337 11.7167C1.68337 11.1167 1.20837 10.4111 0.858374 9.59999C0.508374 8.78888 0.333374 7.92222 0.333374 6.99999C0.333374 6.07777 0.508374 5.21111 0.858374 4.39999C1.20837 3.58888 1.68337 2.88333 2.28337 2.28333C2.88337 1.68333 3.58893 1.20833 4.40004 0.858328C5.21115 0.508328 6.07782 0.333328 7.00004 0.333328C7.92226 0.333328 8.78893 0.508328 9.60004 0.858328C10.4112 1.20833 11.1167 1.68333 11.7167 2.28333C12.3167 2.88333 12.7917 3.58888 13.1417 4.39999C13.4917 5.21111 13.6667 6.07777 13.6667 6.99999C13.6667 7.92222 13.4917 8.78888 13.1417 9.59999C12.7917 10.4111 12.3167 11.1167 11.7167 11.7167C11.1167 12.3167 10.4112 12.7917 9.60004 13.1417C8.78893 13.4917 7.92226 13.6667 7.00004 13.6667Z" fill="#F13C3C" />
        </svg>

    );
};