import React, { useState, useEffect, Fragment } from "react"
import Header from "../../header"
import Footer from "../../footer"
import Categories from "../widgets/categories"
import Filters from "../widgets/filters"
// import { Link } from "react-router-dom"
import { GET_PRODUCTS_LIST_BY_CAT_ID, GET_CAT_NAME_BY_ID, ITEMS_ON_THE_PAGE, APP_TITLE } from "../../../helpers/constants"
import { setNameWithCurrentLang, showCurrencyPrice, changePriceToUsd } from "../../../helpers/constants/functions"
import axiosFetch from "../../../helpers/axios"
import Spinner from "../../../helpers/spinner"
import BreadCrumbs from "../../../helpers/breadcrumbs"
import PageTitle from "../../../helpers/page-title"
import Pagination from '../../../helpers/pagination'
import OneProduct from '../../../helpers/one-product'
import HotDeals from "../widgets/hot-deals"
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";

import "./styles.scss"

function Сategory(props) {
  const { lng, t, currency } = props
  const { id } = useParams()

  const maxFilterPrice = showCurrencyPrice(1000, currency)

  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(maxFilterPrice)
  const [isLoading, setIsLoading] = useState(false)
  const [productsList, setProductsList] = useState([])
  const [categoryInfo, setCategoryInfo] = useState()
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  const [capacityFilter, setCapacityFilter] = useState(null)

  // useEffect(() => {
  //   const maxFilterPrice = showCurrencyPrice(maxValue, currency)
  //   const minFilterPrice = showCurrencyPrice(minValue, currency)
  //   setMaxValue(maxFilterPrice)
  //   setMinValue(minFilterPrice)
  // }, [currency, minValue, maxValue])


  // const getProductsList = (page = currentPage, objMaterials, objWorkZones) => {
  //   setIsLoading(true)

  //   const formData = new FormData()
  //   formData.append("сategoryId", id)
  //   formData.append("currentPage", page)
  //   formData.append("minValue", minValue)
  //   formData.append("maxValue", maxValue)
  //   formData.append("itemsOnPage", ITEMS_ON_THE_PAGE)

  //   formData.append("objMaterials", [objMaterials])
  //   formData.append("objWorkZones", [objWorkZones])

  //   const productsResult = axiosFetch(GET_PRODUCTS_LIST_BY_CAT_ID, formData)
  //   promiseResolved(productsResult, "productsResult")
  // }

  const getProductsList = (page = 0) => {
    setIsLoading(true)
    const priceToUsdMax = changePriceToUsd(maxValue, currency)
    const priceToUsdMin = changePriceToUsd(minValue, currency)
    const formData = new FormData()
    formData.append("сategoryId", id)
    formData.append("currentPage", page)
    formData.append("itemsOnPage", ITEMS_ON_THE_PAGE)
    formData.append("minValue", priceToUsdMin)
    formData.append("maxValue", priceToUsdMax)
    formData.append("capacity", capacityFilter || 0)

    const productsResult = axiosFetch(GET_PRODUCTS_LIST_BY_CAT_ID, formData)
    promiseResolved(productsResult, "productsResult")
  }

  useEffect(() => {

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 500);

    // const getProductsList = (page = 0) => {
    //   setIsLoading(true)
    //   const formData = new FormData()
    //   formData.append("сategoryId", id)
    //   formData.append("currentPage", page)
    //   formData.append("itemsOnPage", ITEMS_ON_THE_PAGE)
    //   formData.append("minValue", minValue)
    //   formData.append("maxValue", maxValue)

    //   const productsResult = axiosFetch(GET_PRODUCTS_LIST_BY_CAT_ID, formData)
    //   promiseResolved(productsResult, "productsResult")
    // }

    const getСategoryInfo = () => {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("сategoryId", id)

      const categoryResult = axiosFetch(GET_CAT_NAME_BY_ID, formData)
      promiseResolved(categoryResult, "categoryResult")
    }


    // const getProductsList = (page = currentPage, objMaterials, objWorkZones) => {
    //   setIsLoading(true)

    //   const formData = new FormData()
    //   formData.append("сategoryId", id)
    //   formData.append("currentPage", page)
    //   formData.append("minValue", minValue)
    //   formData.append("maxValue", maxValue)
    //   formData.append("itemsOnPage", ITEMS_ON_THE_PAGE)

    //   formData.append("objMaterials", [objMaterials])
    //   formData.append("objWorkZones", [objWorkZones])

    //   const productsResult = axiosFetch(GET_PRODUCTS_LIST_BY_CAT_ID, formData)
    //   promiseResolved(productsResult, "productsResult")
    // }


    getProductsList(currentPage)
    getСategoryInfo()
    // setTotalCount(0)
    // setCurrentPage(0)

  }, [id, minValue, maxValue, currentPage])


  const setRangeCommon = (min, max) => {
    getProductsList(currentPage)
    setCurrentPage(0)
    //console.log("min, max", min, max)
    setMinValue(min)
    setMaxValue(max)
  }



  const promiseResolved = (productsResult, state) => {
    Promise.resolve(productsResult).then((arrList) => {

      switch (state) {
        case "productsResult":
          if (arrList.length) {
            setTotalCount(arrList[0].total)
          } else {
            setTotalCount(0)
          }
          setProductsList(arrList)
          break
        case "categoryResult":
          setCategoryInfo(arrList)
          break

        default:
          break
      }

      setIsLoading(false)
    })
  }

  // const range = {
  //   setMin,
  //   setMax,
  // }

  const paginationClick = page => {
    setIsLoading(true)
    setCurrentPage(page)
    // getProductsList(page)
  }

  const renderFilterTopBar = () => {
    const { categoryNameDe, categoryNameEn, categoryNameEsp, categoryNameIt, categoryNameRu } = categoryInfo[0]
    const finalName = setNameWithCurrentLang(lng, categoryNameRu, categoryNameEn, categoryNameDe, categoryNameEsp, categoryNameIt)

    document.title = `${APP_TITLE}${finalName}`
    return (
      <div className="clearfix">
        <div className="row">
          <div className="col col-sm-6 col-md-12">
            <PageTitle text={finalName} />
          </div>
        </div>
      </div>
    )
  }

  const renderProductsItems = (products) => {
    const content = products.map((oneProduct, index) => {
      return <OneProduct oneProduct={oneProduct} showAddToCart={true} key={index} className={"col-sm-6 col-md-4"} />
    })
    return content
  }

  const renderEmptyContainer = () => {
    return (
      <div className="col-sm-6 col-md-12 wow fadeInUp animated no-content" >
        {t('Empty_category')}
      </div>)
  }

  const renderBreadcrumbs = () => {
    const { categoryId, categoryNameDe, categoryNameEn, categoryNameEsp, categoryNameIt, categoryNameRu } = categoryInfo[0]
    const finalName = setNameWithCurrentLang(lng, categoryNameRu, categoryNameEn, categoryNameDe, categoryNameEsp, categoryNameIt)

    return (
      <Fragment>
        <BreadCrumbs
          step1={finalName}
          step1Url={`category/${categoryId}`}
          active={1}
        />
      </Fragment>
    )
  }

  const showPagination = () => {
    return (
      <Pagination
        onClick={paginationClick}
        total={totalCount}
        pageIndex={currentPage}
        pageSize={ITEMS_ON_THE_PAGE}
      />
    )
  }

  // const setCheckFilters = (objMaterials, objWorkZones) => {
  //   setIsLoading(true)
  //   setTimeout(() => {
  //     getProductsList(currentPage, objMaterials, objWorkZones)
  //   }, 500);
  // }
  const content = isLoading ? <Spinner /> : productsList && productsList.length > 0 ? renderProductsItems(productsList) : renderEmptyContainer()
  console.log("totalCount", totalCount)
  return (
    <div className="cnt-home">
      <Header />
      <div className="body-content outer-top-xs">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
              <Categories currentCategory={id} />
              <Filters setRange={setRangeCommon} />
              <div>
                <div className="form-group">
                  <label className="info-title capacity-filter" for="exampleInputEmail1">
                    <div className="label-input ">
                      {t('Capacity')}(ml)
                      <input type="number" value={capacityFilter} onChange={(e) => setCapacityFilter(e.target.value)} className="form-control unicase-form-control text-input" id="exampleInputEmail1" placeholder="30" />
                    </div>
                    <button
                      onClick={() => getProductsList()}
                      disabled={!(capacityFilter > 0)}
                      class="btn-upper btn btn-primary checkout-page-button checkout-continue ">
                      {t('Continue')}
                    </button>
                  </label>

                </div>
              </div>
              <HotDeals />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-9 homebanner-holder" id="categoryPage">
              {categoryInfo && renderFilterTopBar()}
              <div className="tab-pane active " id="grid-container">

                <div className="category-product">
                  {categoryInfo && renderBreadcrumbs()}
                  <div className="row">
                    {totalCount > ITEMS_ON_THE_PAGE && showPagination()}
                    {content}
                  </div>
                  <div className="row">
                    {totalCount > ITEMS_ON_THE_PAGE && showPagination()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({
  lng: state.PrefsReducer.lng,
  currency: state.MenuDataReducer.currency,
});

export default withRouter(compose(connect(mapStateToProps, {}), withNamespaces())(Сategory));
